import React, { useEffect } from 'react';
import withIndexRedirect from 'lib/withIndexRedirect';
import { fullstory } from 'lib/track';

import useRegistrationViewsStack from 'components/Registration/useRegistrationViewsStack';
import Header from 'components/Registration/Header/Header';
import RegistrationView from 'components/Registration/RegistrationView/RegistrationView';
import Registration from 'components/Registration/Registration';

const SignIn = () => {
  const registrationViewProps = useRegistrationViewsStack('signin');

  useEffect(() => fullstory('restart'), []);

  return (
    <>
      <Header {...registrationViewProps} />
      <RegistrationView>
        <Registration {...registrationViewProps} />
      </RegistrationView>
    </>
  );
};

SignIn.getInitialProps = () => ({
  namespacesRequired: ['common', 'registration', 'settings'],
});

export default withIndexRedirect(SignIn);
